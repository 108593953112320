body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.qr-wrapper {
  position: absolute;
  width: calc((100vw - 728px) / 2);
  max-width: 500px;
  max-height: 100vh;
}

.qr-wrapper > p {
  color: black;
  max-width: calc((100vw - 728px) / 2);
  padding: 0;
  padding-top: 10px;
  margin: 0;
  font-weight: bold;
}

.qr-wrapper > a {
  color: black;
  max-width: calc((100vw - 728px) / 2);
  padding: 10px;
}

.qr {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: 0;
  padding: 0;
  margin: 10px;
}

.instruction-wrapper {
  position: absolute;
  width: calc((100vw - 728px) / 2);
  max-width: 500px;
  top: 0;
  right: 0;
  height: 100vh;
}

.instruction-wrapper > div {
  color: black;
  max-width: calc((100vw - 728px) / 2);
  padding: 0;
  padding-top: 10px;
  margin: 0;
  padding: 10px;
  white-space: pre-wrap;
  /* text-align: center;
  vertical-align: middle; */
}


@media screen and (max-device-width: 1050px) {
  .instruction-wrapper {
    display: none;
  }
  .qr-wrapper {
    display: none;
  }
}