body {
  background-color: rgb(243, 243, 243);
}

.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}

.App header {
  background-color: #181717;
  height: 10vh;
  min-height: 50px;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
}

header > h3 {
  margin-left: auto;
  padding-bottom: 4px;
}

main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: grey;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
}

form button {
  width: max(20%, 180px);
  background-color: #0b93f6;
}


input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid black;
}




ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.hidden p {
  text-shadow: 1px 1px 5px white !important;
  color: transparent !important;
}

.hidden:active p {
  text-shadow: none !important;
  color: white !important;
}

.hidden:hover p {
  text-shadow: none !important;
  color: white !important;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
  word-break: break-word;
}

.received p {
  background: #e5e5ea;
  color: black;
  word-break: break-word;
}

.positive p {
  background-color: #90db2d !important;
  color: white;
  word-break: break-word;
}

.message .robot {
  margin-top: 0px;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

@media screen and (max-device-width: 728px) {
  .hidden p {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .sent p {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
  .received p {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
  .positive p {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
}